import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import DownloadTable22 from 'components/Downloads/Desktop_Software/InstarVision_for_macOS/DownloadTable22';
import DownloadTable21 from 'components/Downloads/Desktop_Software/InstarVision_for_macOS/DownloadTable21';
import NavButtons from 'components/Downloads/Desktop_Software/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision for macOS Downloads",
  "path": "/Downloads/Desktop_Software/InstarVision_for_macOS/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Software and Documentation Downloads",
  "image": "../D_Desktop_Software_SearchThumb.png",
  "social": "/images/Search/D_Desktop_Software_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Download Area - Desktop Software' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Software Downloads and Usermanuals for your INSTAR Products' image='/images/Search/D_Downloads_SearchThumb.png' twitter='/images/Search/D_Downloads_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Downloads/Desktop_Software/InstarVision_macOS/' locationFR='/fr/Downloads/Desktop_Software/InstarVision_macOS/' crumbLabel="InstarVision" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "desktop-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#desktop-software",
        "aria-label": "desktop software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Desktop Software`}</h2>
    <h2 {...{
      "id": "instarvision-2-for-macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instarvision-2-for-macos",
        "aria-label": "instarvision 2 for macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`InstarVision 2 for macOS`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#instarvision-2-for-macos"
        }}>{`InstarVision 2 for macOS`}</a>
        <div parentName="li" {...{
          "className": "gatsby-highlight",
          "data-language": "text"
        }}><pre parentName="div" {...{
            "className": "language-text"
          }}><code parentName="pre" {...{
              "className": "language-text"
            }}>{`  - [Version history with features](#version-history-with-features)
      - [Version 2.2](#version-22)
      - [Version 2.1](#version-21)`}</code></pre></div>
      </li>
    </ul>
    {/* /TOC */}
    <h4 {...{
      "id": "version-history-with-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#version-history-with-features",
        "aria-label": "version history with features permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version history with features`}</h4>
    <h5 {...{
      "id": "version-22",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-22",
        "aria-label": "version 22 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.2`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionApple/InstarVision__macOS_2.2a.pkg"
        }}>{`Download`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <DownloadTable22 mdxType="DownloadTable22" />
    <EuiSpacer mdxType="EuiSpacer" />
    {/* | Release Notes |
     |--|
     | Apect ratio for live video. The aspect ratio of the video is now maintained |
     | SmartP2P from external now possible |
     | Tour function is now like in Windows and can be stopped |
     | In the upper left corner the name of the software is now displayed, before there were problems with German because it was not faded in and only the placeholder was displayed. | */}
    <h5 {...{
      "id": "version-21",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#version-21",
        "aria-label": "version 21 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version 2.1`}</h5>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/InstarVisionApple/InstarVision__macOS_2.1.pkg"
        }}>{`Download`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <DownloadTable21 mdxType="DownloadTable21" />
    <EuiSpacer mdxType="EuiSpacer" />
    {/* | Release Notes |
     |--|
     | Initial Release | */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      